<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-domain"
            :title="baseMaterialCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0"
                     @click="$router.push({name: 'Accounts', params: {envCode: envSelected.value} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="canAdd" no-gutters>
            <v-col cols="12" md="12">

              <template v-if="!account">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                      <div :class="{'purple--text': step === 1 , 'grey--text': step !== 1}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 1 , 'grey--text': step !== 1}">
                          mdi-domain
                        </v-icon>
                        <span>General</span>
                      </div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                      <div :class="{'purple--text': step === 2 , 'grey--text': step !== 2}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 2 , 'grey--text': step !== 2}">mdi-lock
                        </v-icon>
                        <span>Security</span>
                      </div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                      <div :class="{'purple--text': step === 3 , 'grey--text': step !== 3}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 3 , 'grey--text': step !== 3}">mdi-apps
                        </v-icon>
                        <span>Multi Tenancy</span>
                      </div>
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card
                          flat
                          class="mb-1"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-text-field
                                  label="Organization Name *"
                                  v-model="account.name"
                                  prepend-icon="mdi-domain"
                                  :rules="[()=> !!account.name || 'This field is required!']"
                                  required
                                  counter
                              />
                            </v-col>

                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card
                          class="mb-1"
                          flat
                      >
                        <v-card-text>
                          <user-account-security
                              :fieldAccess="fieldAccess"
                              :groups="groups"
                              :strategies="strategies"
                              :context="account"
                              :env-selected="envSelected"
                              @updateThrottlingStrategies="updateThrottlingStrategies"
                          ></user-account-security>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card
                          class="mb-2"
                          flat
                      >
                        <v-card-text>
                          <user-account-multitenancy
                              :mode="'create'"
                              :fieldAccess="fieldAccess"
                              :section="'account'"
                              :context="account"
                              :env-selected="envSelected"
                              :tenants="tenants"
                          ></user-account-multitenancy>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="canAdd">
            <v-col cols="12" md="12" class="text-right pr-0 mr-0">
              <v-btn
                  color="secondary"
                  @click="step = 2"
                  v-if="step === 1"
                  :disabled="!stepper.step1"
                  class="mr-3"
              >
                <v-icon class="mr-1">mdi-chevron-right</v-icon>
                Next
              </v-btn>

              <v-btn
                  class="mr-2"
                  color="error"
                  @click="step=1"
                  v-if="step === 2"
              >
                <v-icon class="mr-1">mdi-chevron-left</v-icon>
                Back
              </v-btn>

              <v-btn
                  class="mr-3"
                  color="secondary"
                  @click="step = 3"
                  v-if="step === 2"
                  :disabled="!stepper.step2"
              >
                <v-icon class="mr-1">mdi-chevron-right</v-icon>
                Next
              </v-btn>

              <v-btn
                  color="error"
                  @click="step=2"
                  v-if="step === 3"
                  class="mr-2"
              >
                <v-icon class="mr-1">mdi-chevron-left</v-icon>
                Back
              </v-btn>

              <v-btn
                  class="mr-3"
                  :disabled="!stepper.step1 || !stepper.step2 || !stepper.step3"
                  v-if="step ===3 && canAccess({route: '/organization/accounts', method: 'put'})"
                  color="success" @click="updateAccount">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>

            </v-col>
          </v-row>
          <v-row v-else class="mt-5">
            <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
              <h2>Oops!</h2>
              <p>
                It appears you have no Groups or no Applications created yet.<br/>
                Therefore you cannot create any Organization Accounts.
              </p>
              <p>
                If you do not have groups yet, head to the <b>Groups</b> Module from the top right User Menu.<br/>
                Create some groups, then come back to this section and you will be able to create organization accounts.
              </p>
              <p>
                If you do not have Applications yet, head to the <b>Application</b> Module from the left Menu.<br/>
                Create your application, then come back to this section and you will be able to create organization
                accounts.
              </p>
              <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                Read the Docks
              </v-btn>
              <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToGroups">
                <v-icon small color="white" class="mr-1">mdi-account-group</v-icon>
                open Groups
              </v-btn>
            </v-alert>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import usersMixins from "./components/mixins";
import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import UserAccountSecurity from "@/views/internal/pages/users/components/security";
import UserAccountMultitenancy from "@/views/internal/pages/users/components/multitenancy";

export default {
  name: 'Account',

  mixins: [globalMixins, usersMixins, fieldsMixins],

  data: () => ({
    fieldAccess: {
      groups: true
    },
    step: 1,
    stepper: {
      step1: false,
      step2: false,
      step3: false
    },
    loading: false,
    search: '',
    account: null,
    groups: [],
    strategies: [],
    tenants: [],
    canAdd: false
  }),

  components: {
    UserAccountMultitenancy,
    UserAccountSecurity,
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    }
  },

  async created() {
    this.initialize();
  },

  computed: {
    baseMaterialCardTitle() {
      let label = 'Creating new Organization ...';
      if (this.account) {
        return `Creating new Organization ${this.account.name ? `'${this.account.name}'` : ''}`;
      } else {
        return '';
      }
    }
  },

  watch: {
    async 'search'(val) {
      if (val && val.length >= 3) {
        this.search = val;
      }
    },

    "account": {
      handler: function (to, from) {
        this.stepper.step1 = false;
        this.stepper.step2 = false;
        this.stepper.step3 = false;

        if ((to.name && to.name.trim() !== '')) {
          this.stepper.step1 = true;
        }

        if (this.stepper.step1 && to.groups.length > 0) {
          this.stepper.step2 = true;
        }

        if (this.stepper.step2 && to.tenants.length > 0) {
          this.stepper.step3 = true;
        }
      },
      deep: true
    }
  },

  methods: {

    goToGroups() {
      this.$router.push({name: 'Groups', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.accounts);
    },

    reset() {
      if (confirm("Are you sure you want to reset the page? (All your changes will be lost)")) {
        this.initialize();
      }
    },

    async initialize() {
      this.account = null;

      const groups = await this.getSendData({
        'url': '/consoleapi/organization/groups',
        'method': 'get'
      });

      const tenants = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/default/tenants`,
        'method': 'get',
        params: {
          noEnv: true
        }
      });

      this.groups = [];
      this.strategies = [];

      this.groups = groups.items;
      this.tenants = tenants.items;

      if (this.groups && this.groups.length && this.tenants && this.tenants.length) {
        this.canAdd = true;
      }

      this.account = {
        name: '',
        config: {},
        groups: [],
        tenants: [],
        security: {
          throttling: {}
        }
      }
    },

    updateThrottlingStrategies(newList) {
      this.account.security.throttling.strategies = newList;
    },

    updateAccount() {
      const self = this;

      let newAccount = this._lodash.cloneDeep(this.account);

      if (!this.validateAccountRecord(newAccount)) {
        return false;
      }

      newAccount = this.cleanUpAccount(newAccount);

      let apiOptions = {
        url: `/consoleapi/organization/accounts`,
        method: "put",
        params: newAccount
      };

      self.getSendData(apiOptions)
          .then((response) => {
            self.pushMessage({
              type: 'success',
              title: `Organization Added`,
              text: `Organization Information, Security and Multitenancy have been created!`
            });
            //redirect to listing
            setTimeout(() => {
              self.goToPage({route: 'Accounts', params: {'envCode': self.envSelected.value}});
            }, 2000);

          });
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
